import React, { useEffect, useState } from "react";
// import ReactPWAInstallProvider, { useReactPWAInstall } from "react-pwa-install";
import "./Component-css/Downloadbutton.css?v=0.1";
import "../Components/Component-css/Header.css";
import css from "./Component-css/Nav.module.css";
// let deferredPrompt;
const Downloadbutton = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  return (
    <div className={`${css.box} rounded text-center`}>
      <button
        onClick={onClick}
        className={`btn btn-primary btn-sm bg-transparent border-0 ${css.box} rounded p-0`}
        style={{ fontWeight: 500, backgroundColor: "#3A427D" }}
      >
        <div className="bell">
          <picture className={`${css.moneyIcon_container}`}>
            <i
              style={{
                color: "#fbc905",
                paddingRight: "5px",
                fontSize: "20px",
              }}
              className="fa-solid fa-download mr-0"
            />
          </picture>
        </div>
        {/* <img
            src="https://hiplay.in/Images/android.png"
            alt=""
            style={{ marginRight: 10 }}
            width="15px"
          />
          <span
            style={{
              color: "#ffffff",
              backgroundColor: "#3A427D",
              fontWeight: "700",
            }}
          >
            {" "}
            DOWNLOAD OUR APP
          </span>

          <img
            src="https://hiplay.in/Images/dowloadIcon.png"
            alt=""
            style={{ marginLeft: 10 }}
            width="13px"
          /> */}
      </button>
    </div>
  );
};

export default Downloadbutton;
