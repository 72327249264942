import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Rightcontainer from "../Components/Rightcontainer";
import "../css/terms.css";
//import { Interweave } from "interweave";
const All_policy = () => {
  const [data, setData] = useState();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    var baseUrl = beckendLiveApiUrl;
  }

  return (
    <div>
      <div
        className="leftContainer"
        style={{ minHeight: "100vh", height: "100%" }}
      >
        <div className="mt-5 py-4 px-3">
          {/* <Accordion style={{marginTop:"10px"}}> */}
          {/* <AccordionSummary
                            expandIcon={<i class="fa-solid fa-chevron-down"></i>}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        > */}
          <button className="w-100" style={{ color: "#000", fontSize: "20px" }}>
            All Policy
          </button>
          {/* </AccordionSummary> */}
          {/* <AccordionDetails> */}
          <ul
            style={{ fontSize: "16px" }}
            className=" list-unstyled ml-2 mt-2 "
          >
            <div className="unstyled">
              <Link to="/term-condition" >
                <li style={{ color: "#fff"  ,fontFamily:'sans-serif'}} className="col giveHover">
                  Terms & Conditions
                </li>
              </Link>
              <Link to="/PrivacyPolicy">
                <li style={{ color: "#fff" ,fontFamily:'sans-serif' }} className="col giveHover">
                  Privacy policy
                </li>
              </Link>

              <Link to="/refund-policy">
                <li style={{ color: "#fff" ,fontFamily:'sans-serif' }} className="col giveHover">
                  Refund/Cancellatioin Policy
                </li>
              </Link>

              <Link to="/contact-us">
                {" "}
                <li style={{ color: "#fff" ,fontFamily:'sans-serif' }} className="col giveHover">
                  Contact Us
                </li>{" "}
              </Link>

              <Link to="/responsible-gaming">
                {" "}
                <li className="col giveHover" style={{ color: "#fff" ,fontFamily:'sans-serif' }}>
                  Responsible Gaming{" "}
                </li>
              </Link>
              <Link to="About">
                {" "}
                <li style={{ color: "#fff" ,fontFamily:'sans-serif' }} className="col giveHover">
                  About Us
                </li>{" "}
              </Link>
            </div>
          </ul>
          {/* </AccordionDetails> */}
          {/* </Accordion> */}
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};
export default All_policy;
