import React, { useEffect, useState } from "react";
import "../css/layout.css";
import css from "../css/Profile.module.css";
import { Link, useHistory } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import axios from "axios";
// import { Link, useHistory } from 'react-router-dom';
// import Profile from './Profile';
// import { Image } from 'react-bootstrap';
import Rightcontainer from "../Components/Rightcontainer";
import Swal from "sweetalert2";
import Header from "../Components/Header";

const Profile1 = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [show, setShow] = useState(false);

  const [referral, setCode] = useState("");
  const [Id, setId] = useState(null);
  const [profile, setProfile] = useState();
  const [portcss, setPortcss] = useState(css.active_tab);
  const [portcss1, setPortcss1] = useState(css.inactive_tab);
  const [crushcss, setCrushcss] = useState(true);
  const [holder_name, setHolder_name] = useState();
  const [account_number, setAccount_number] = useState();
  const [ifsc_code, setIfsc_code] = useState();
  const [upi_id, setUpi_id] = useState();
  const [refcode, setRefcode] = useState();
  const history = useHistory();

  const logout = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `logout`,
        {
          headers: headers,
        },
        { headers }
      )
      .then((res) => {
        // setUser(res.data)
        localStorage.removeItem("token");
        //window.location.reload();
        history.push("/login");
        window.location.reload(true);
      })
      .catch((e) => {
        // alert(e.msg)
      });
  };

  const UserALL = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setProfile(res.data);
        setId(res.data._id);
        TotalGame(res.data._id);
        setName(res.data.Name);
        setCode(res.data.referral);
        setHolder_name(res.data.holder_name);
        setAccount_number(res.data.account_number);
        setIfsc_code(res.data.ifsc_code);
        setUpi_id(res.data.upi_id);
        setRefcode(res.data.referral_code);
      })
      .catch((e) => {
        // alert(e.msg)
      });
  };
  const [Name, setName] = useState();

  const UpdateProfile = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          Name,
        },
        { headers }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data == "User name already exist!") {
          Swal.fire({
            title: res.data,
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          setName(res.data);
          UserALL();
        }
      });
  };

  const updateBankDetails = async (e) => {
    e.preventDefault();
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          holder_name,
          account_number,
          ifsc_code,
          upi_id,
        },
        { headers }
      )
      .then((res) => {
        console.log(res.data);
        if (res.status == 200) {
          setShow((prev) => !prev);
          UserALL();
          let message;
          message = res.data.msg;
          if (!res.data.msg) {
            message = "something went wrong";
          }
          Swal.fire({
            title: message,
            icon: res.data.submit ? "success" : "error",
            confirmButtonText: "OK",
          });
        }
      });
  };

  const update_RefCode = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    if (refcode == referral) {
      Swal.fire({
        title: "Can't use own referral code",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      const data = await axios
        .patch(
          baseUrl + `user/edit`,
          {
            referral,
          },
          { headers }
        )
        .then((res) => {
          console.log(res.data);
          if (res.status == 200) {
            UserALL();
            let message;
            message = res.data.msg;
            if (!res.data.msg) {
              message = "Invalid referral Code";
            }
            Swal.fire({
              title: message,
              icon: res.data.submit ? "success" : "error",
              confirmButtonText: "OK",
            });
          }
        });
    }
  };
  //// total game
  const [total, setTotal] = useState();

  const TotalGame = (Id) => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .get(baseUrl + `total/user/all/${Id}`, { headers })
      .then((res) => {
        setTotal(res.data);
      })
      .catch((e) => {});
  };

  const [pic, setPic] = useState();

  const Result = async (file) => {
    if (file) {
      const access_token = localStorage.getItem("token");
      // // console.log(access_token)
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      const formData = new FormData();
      formData.append("avatar", file);

      // console.log(formData)
      await fetch("https://kycnote.rkbatting.com/users/me/avatar", {
        method: "POST",
        body: formData,
        headers,
      }).then((res) => {
        UserALL();
      });
    }
  };
  //avatar

  useEffect(() => {
    UserALL();
  }, []);

  return (
    <>
      <Header user={profile} />
      <div className="profile">
        <div className="leftContainer bg-transparent p-3">
          <div className="Inner_profile">
            <div className="mt-5 py-4 px-3">
              <div className={`${css.center_xy} py-5`}>
                <div className="profile_box">
                  <div>
                    <label>
                      <input
                        className="d-none"
                        type="file"
                        onChange={(e) => Result(e.target.files[0])}
                        accept="image/*"
                        required
                      />
                      <picture>
                        {/* {image} */}
                        {console.log(process.env.PUBLIC_URL)}
                        {profile && profile.avatar ? (
                          <img
                            className="profile_pic"
                            height="60px"
                            width="60px"
                            src={`https://kycnote.rkbatting.com/${
                              profile && profile.avatar
                            }`}
                            alt="avtar"
                            style={{ borderRadius: "50px" }}
                          />
                        ) : (
                          <img
                            className="profile_pic"
                            height="60px"
                            width="60px"
                            src={process.env.PUBLIC_URL + `/user.png`}
                            alt="user"
                            style={{ borderRadius: "50px" }}
                          />
                        )}
                      </picture>
                    </label>
                  </div>

                  <div
                    className={`${portcss} font-weight-bold mb-1 text-white position-relative`}
                  >
                    <img
                      src="/badge.png"
                      alt="bg"
                      className="img-fluid"
                      width={"250px"}
                    />
                    {/* <i className="fa-solid fa-user mr-1"></i> */}
                    <div
                      className="position-absolute"
                      style={{ top: "15px", left: "25px", fontSize: "25px" }}
                    >
                      {profile && profile.Name}
                      <img
                        className={`ml-2`}
                        width="20px"
                        src={process.env.PUBLIC_URL + "/Images/draw.png"}
                        alt=""
                        onClick={() => {
                          setPortcss(css.inactive_tab);
                          setPortcss1(css.active_tab);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="details">
                    <span
                      className={`${css.battle_input_header} mr-1 text-white`}
                    >
                      <i className="fa-solid fa-phone mr-1 phone"></i>
                      {profile && profile.Phone}
                    </span>
                  </div>
                  <div className="details">
                    <span className={`mr-1 text-white`}>
                      <i className="fa-solid fa-envelope mr-1 mail"></i>
                      user@gmail.com
                    </span>
                    <img
                      style={{ right: "-30px", bottom: "4px" }}
                      className={`ml-2 position-absolute`}
                      width="20px"
                      src={process.env.PUBLIC_URL + "/Images/draw.png"}
                      alt=""
                    />
                  </div>

                  <div className="details">
                    <span className={`mr-1 text-white`}>
                      <i className="fa-solid fa-search mr-1 kyc"></i>
                      KYC Verification
                    </span>
                    <img
                      style={{ right: "-30px", bottom: "4px" }}
                      className={`ml-2 position-absolute`}
                      width="20px"
                      src={process.env.PUBLIC_URL + "/Images/checked.png"}
                      alt=""
                    />
                  </div>
                  {profile && !profile.referral && (
                    <div className="details refer_input">
                      <div className={`d-flex align-items-center`}>
                        <picture>
                          <img
                            height="24px"
                            width="24px"
                            src={
                              process.env.PUBLIC_URL +
                              "Images/Header/referEarn.png"
                            }
                            alt=""
                            className="snip-img bg-white rounded"
                          />
                        </picture>

                        <div className="pl-2">
                          <div
                            className={`${css.text_uppercase} ${css.moneyBox_header}`}
                            style={{ fontSize: "1.1rem", fontWeight: "650" }}
                          ></div>

                          <input
                            type="text"
                            className={`text-white ${css.MuiInputBase_input}`}
                            aria-describedby="basic-addon1"
                            value={referral}
                            placeholder="Enter Refer Code"
                            onChange={(e) => {
                              setCode(e.target.value);
                            }}
                          />

                          <Link
                            style={{ right: "-30px", bottom: "4px" }}
                            className={`ml-2 position-absolute`}
                            onClick={() => {
                              update_RefCode();
                            }}
                          >
                            <img
                              width="20px"
                              src={
                                process.env.PUBLIC_URL + "/Images/checked.png"
                              }
                              alt=""
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className={`text-bold my-3 ${portcss1}`}>
                  <div
                    className={`${css.MuiFormControl_root} ${css.MuiTextField_root}`}
                    style={{ verticalAlign: "bottom" }}
                  >
                    <div
                      className={`${css.MuiInputBase_root} ${css.MuiInput_root} ${css.MuiInput_underline} ${css.MuiInputBase_formControl} ${css.MuiInput_formControl}`}
                    >
                      <input
                        aria-invalid="false"
                        type="text"
                        maxLength={"20"}
                        className={`${css.MuiInputBase_input} ${css.MuiInput_input}`}
                        placeholder="Enter Username"
                        value={Name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <img
                    className="ml-2"
                    width="20px"
                    src={
                      process.env.PUBLIC_URL +
                      "/Images/select-blue-checkIcon.png"
                    }
                    alt=""
                    onClick={() => {
                      setPortcss(css.active_tab);
                      setPortcss1(css.inactive_tab);
                      UpdateProfile(Id);
                    }}
                  />
                </div>

                <div className="wallet_box">
                  {/* <Link
                    className={`boxes d-flex align-items-center`}
                    to="/wallet"
                  >
                    <i
                      style={{ color: "#000000" }}
                      class="fa-solid fa-2x fa-wallet"
                    ></i>
                    <img
                      src="/Images/LandingPage_img/addcash.png"
                      alt="wallet"
                      width={"40px"}
                    />
                    <div
                      className={`${css.mytext} ml-2 text-white font-weight-bold`}
                    >
                      My Wallet
                    </div>
                  </Link> */}
                  <div className="">
                    <div className="">
                      <Link
                        className={`d-flex align-items-center boxes bg-success`}
                        to={
                          profile && profile.verified === `unverified`
                            ? `/Kyc2`
                            : `/Profile`
                        }
                      >
                        {/* <picture>
                            <img
                              width="40px"
                              src={
                                process.env.PUBLIC_URL +
                                "/Images/kyc-icon-new.png"
                              }
                              alt=""
                            />
                          </picture> */}
                        <div
                          style={{ fontSize: "1rem" }}
                          className={`ml-2 ${css.mytext} text-white font-weight-bold`}
                        >
                          {profile && profile.verified === `unverified`
                            ? "Complete KYC"
                            : profile && profile.verified === "pending"
                            ? "In Process"
                            : profile && profile.verified === "verified"
                            ? "Completed Kyc ✅"
                            : "Completed Kyc ✅"}{" "}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="battle">
              <div className="px-3 py-1 battle_info">
                <div
                  style={{ background: "#02909b69", borderColor: "#fff" }}
                  className={`d-flex align-items-center boxes ${css.position_relative}`}
                >
                  <div className="">
                    <div className="d-flex align-items-center ">
                      <picture>
                        <img
                          height="40px"
                          width="40px"
                          src={process.env.PUBLIC_URL + "/Images/rupee.png"}
                          alt=""
                          className="snip-img mr-1"
                        />
                      </picture>
                      <div
                        className={`${css.text_uppercase} ${css.moneyBox_header}`}
                        style={{ fontSize: "1.1rem", fontWeight: "650" }}
                      >
                        Coin Won
                      </div>
                    </div>
                    <div className="">
                      <picture className="mr-1">
                        {/* <img
                        height="auto"
                        width="21px"
                        src={
                          process.env.PUBLIC_URL +
                          "/Images/global-rupeeIcon.png"
                        }
                        alt=""
                        className="snip-img"
                      /> */}
                      </picture>
                      <span
                        className={css.moneyBox_text}
                        style={{ fontSize: "1.5em", bottom: "-1px" }}
                      >
                        {profile && profile.wonAmount}
                      </span>
                    </div>
                    <span className={css.thin_divider_x}></span>
                  </div>
                </div>
              </div>
              <div className="px-3 py-1 battle_info">
                <div
                  style={{ background: "#763978" }}
                  className={`d-flex align-items-center boxes ${css.position_relative}`}
                >
                  <div className="">
                    <div className="d-flex align-items-center ">
                      <picture>
                        <img
                          height="32px"
                          width="32px"
                          src={
                            process.env.PUBLIC_URL +
                            "/Images/referral-signup-bonus-new.png"
                          }
                          alt=""
                          className="snip-img mr-1"
                        />
                      </picture>
                      <div
                        className={`${css.text_uppercase} ${css.moneyBox_header}`}
                        style={{ fontSize: "1.1rem", fontWeight: "650" }}
                      >
                        Referral Earning
                      </div>
                    </div>
                    <div className="">
                      <span
                        className={css.moneyBox_text}
                        style={{ fontSize: "1.5em", bottom: "-1px" }}
                      >
                        {profile && profile.referral_earning}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-3 py-1 battle_info">
                <div
                  style={{ background: "#2d2ed969" }}
                  className={`d-flex align-items-center boxes ${css.position_relative}`}
                >
                  <div className="">
                    <div className="d-flex align-items-center ">
                      <picture>
                        <img
                          height="32px"
                          width="36px"
                          src={
                            process.env.PUBLIC_URL + "/Images/battleplayed.png"
                          }
                          alt=""
                          className="mr-1"
                        />
                      </picture>
                      <div
                        className={`${css.text_uppercase} ${css.moneyBox_header}`}
                        style={{ fontSize: "1.1rem", fontWeight: "650" }}
                      >
                        Battle Played
                      </div>
                    </div>
                    <div className="snip-div">
                      <span
                        className={css.moneyBox_text}
                        style={{ fontSize: "1.5em", bottom: "-1px" }}
                      >
                        {total && total}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-1 snipcss-A1eLC snip-div">
            <Link
              to="/login"
              className={`${css.center_xy}  text-uppercase py-4 font-weight-bolder text-muted`}
              onClick={(e) => logout(e)}
            >
              <span className="text-white mt-5 log_out">
                Log Out <i className="fa fa-sign-out " />
              </span>
            </Link>
          </div>
          <center>{/* <div className={css.divider_x}></div> */}</center>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
      {/* <ModalProfile style3={
                profileModalstyle
            } Enter={Enter}/> */}
      <div className={css.kyc_select} id="profileModal">
        <div className={css.overlay} />
        <div
          className={`${css.box}`}
          style={{
            bottom: "0px",
            position: "absolute",
          }}
        >
          <div className={css.bg_white}>
            <div className={`${css.header} ${css.cxy} ${css.flex_column}`}>
              <picture>
                <img
                  height="80px"
                  width="80px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar2.png"}
                  alt=""
                />
              </picture>
              <div className={`${css.header_text} mt-2`}>Choose Avatar</div>
            </div>
            <div className="mx-3 pb-3" style={{ paddingTop: "200px" }}>
              <div className="row justify-content-between col-10 mx-auto">
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar1.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar2.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar3.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar4.png"}
                  alt=""
                />
              </div>
              <div className="row justify-content-between col-10 mx-auto mt-3">
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar5.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar6.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar7.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar8.png"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Profile1;
