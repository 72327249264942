import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../css/landing.css";
import live from "../../assets/images/live.png";

export default function Landing() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [open, setOpen] = useState(false);
  const [userAllData, setUserAllData] = useState();
  const [commission, setcommission] = useState();
  const [refer, setrefer] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const [blockMessage, setBlockMessage] = useState("");
  const [userBlock, setUserBlock] = useState(0);
  const [msg, setmsg] = useState("");

  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    setcommission(data.commission);
    setrefer(data.refer);
    setmsg(data.msg);

    return setWebsiteSettings(data);
  };

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
        const passworduser = res.data.Password;
        // if(passworduser.length > 13){
        //   localStorage.removeItem("token");
        // }

        // const user = res.data;

        // const blockValue = user.Wallet_balance - (user.wonAmount + user.totalDeposit + user.totalBonus -
        //   (user.loseAmount + user.totalWithdrawl + user.withdraw_holdbalance + user.hold_balance + user.totalPenalty));
        //   setUserBlock(blockValue);
        //   console.warn(blockValue);

        // if (userBlock > 0) {
        //   Swal.fire({
        //     icon: "error",
        //     title: "Oops...",
        //     text: "Please Contact Support Number 8233292045",
        //   });
        //   localStorage.removeItem("token");
        // }

        // if (userBlock < 0) {
        //   Swal.fire({
        //     icon: "error",
        //     title: "Oops...",
        //     text: "Please Contact Support Number 8233292045",
        //   });
        //   localStorage.removeItem("token");
        // } else if (userBlock < 0) {
        //   Swal.fire({
        //     icon: "error",
        //     title: "Oops.....",
        //     text: "Please Contact Support Number 8233292045",
        //   });
        //   localStorage.removeItem("token");
        // }
      })

      .catch((e) => {
        if (e.response?.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          //window.location.href = "/login";
          //window.location.reload()
          //history.push("/login")
        }
      });
  };

  useEffect(() => {
    if (process.env.REACT_APP_DEBUG === "true") {
      // debugger;
    }

    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      //window.location.reload()
    }
    role();
    fetchData();
  }, [userBlock]);

  // useEffect(() => {

  // }, [userBlock]);

  return (
    <>
      <div></div>

      {userBlock > 0 ? <div>{blockMessage}</div> : <div>Login Panel</div>}

      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: "35px" }}>
          <div className="header_top_message">
            <span>Commission:5% ◉ Referral: 2% For All Games</span>
          </div>
          <div className="header_top_message">
            <span>{msg}</span>
          </div>

          {/*  <div className="alert alert-primary" role="alert">
प्लेयर्स कृपया ध्यान दे आप सभी को KingAdda.Com की तरफ से दीपावली की हार्दिक शुभ कामनाये आज आप सभी से कोई कमीशन चार्ज नहीं किया जायगा ऑफर सिर्फ आज रात 12 बजे तक रहेगा धन्यवाद
</div>*/}

          <section className="games-section p-3">
            {/* <div style={{ height: "130px" }} id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    style={{ width: "450px", height: "130px" }}
                    src={"https://ik.imagekit.io/qd1zdw8qs/slider_11.png?updatedAt=1714462014298"
                    }
                    alt="First slide"
                  />                  </div>
                <div class="carousel-item">
                  <img
                    width="100%"
                    style={{ height: "130px" }}
src={"https://ik.imagekit.io/wnm4oiopu/Screenshot%202024-04-30%20at%202.53.51%20AM.png?updatedAt=1714425885409"}
                    alt="Second slide" />
                </div>
              </div>
            </div> */}

            <div className="d-flex align-items-center games-section-title"></div>
            <div className="games-section-headline mt-2 mb-1">
              <div className="games-window">
                <Link
                  className="gameCard-container"
                  to={`/Homepage/Ludo%20Classics%20Lite`}
                >
                  <span className="blink live text-white">
                    ◉ <img src={live} alt="Live" width={"30px"} />
                  </span>
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL + "/Images/Homepage/Ludo1.png"
                      }
                      // src="https://ik.imagekit.io/wnm4oiopu/dm6rPjl.png?updatedAt=1714425974021"
                      alt=""
                    />
                  </picture>
                  {/* <div className="gameCard-title">
                    <span className="d-none text-dark d-block text-right">
                      ◉ LUDO CLASSIC
                    </span>
                  </div> */}
                  {/* <div className="goverlay">
                    <div className="text">LIVE</div>
                  </div> */}
                </Link>
                <Link className="gameCard-container">
                  {/* <span className="d-none blink text-primary d-block text-right">
                    COMING SOON
                  </span> */}
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL + "/Images/Homepage/Ludo2.png"
                      }
                      alt=""
                    />
                  </picture>
                  {/* <div className="gameCard-title">
                    <span className="d-none text-dark d-block text-right">
                      ◉  LUDO POPULAR
                    </span>
                  </div> */}
                  {/* <div className="goverlay">
                    <div className="text">WhatsApp Support</div>
                  </div> */}
                </Link>

                <Link className="gameCard-container">
                  {/* <span className="d-none blink text-primary d-block text-right">
                    COMING SOON
                  </span> */}
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL + "/Images/Homepage/snake.png"
                      }
                      alt=""
                    />
                  </picture>
                </Link>

                <Link className="gameCard-container">
                  {/* <span className="d-none blink text-primary d-block text-right">
                    COMING SOON
                  </span> */}
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={process.env.PUBLIC_URL + "/Images/Homepage/card.png"}
                      alt=""
                    />
                  </picture>
                </Link>
              </div>
            </div>
            <h4 className="text-center text-white popins mt-3">
              “ALL THE BEST FOR GAME’’
            </h4>
            <hr></hr>
          </section>

          {/* <div className="collapseCard-container">
            <div className="collapseCard">
              <a href="#" target="_blank" style={{ textDecoration: "none" }}>
                <div
                  className="collapseCard-body"
                  style={{
                    height: "64px",
                    opacity: 1,
                    transition: "height 0.3s ease 0s, opacity 0.3s ease 0s",
                  }}
                >
                  <div className="collapseCard-text text-primary">
                    How to win money ?
                  </div>
                </div>
              </a>
              <div
                className="collapseCard-header"
                style={{ left: "22px", transition: "left 0.3s ease 0s" }}
              >
                <picture>
                  <img
                    height="10px"
                    width="14px"
                    src="https://khelbro.com/images/global-ytPlayIcon.png"
                    alt=""
                  />
                </picture>
                <div className="collapseCard-title ml-1 mt-1">Video Help</div>
              </div>
            </div>
          </div>

          <div style={{ paddingBottom: "60px" }} className="w1">
            <a href="https://wa.me/+918769022045?text=hello%20admin%20i%20need%20help">
              <button className="w2">
                <span className="w3">
                  किसी भी समस्या के लिए व्हाट्सप्प पर कांटेक्ट करे।
                </span>
              </button>
            </a>
          </div> */}

          {/* <section className="footer">
            <div className="footer-divider" />
          </section> */}
          {/* <div className="downloadButton">
            <Downloadbutton />
          </div> */}

          <div style={{ paddingBottom: "60px" }} className="w1">
            <a href="https://wa.me/+917424874582?text=hello%20admin%20i%20need%20help">
              <div className="w2 whatsapp">
                <i className="fa fa-whatsapp" />
              </div>
              <button className="w2">
                <span
                  className="w3 text-light ml-5 pl-1"
                  style={{ fontSize: "13px" }}
                >
                  किसी भी समस्या के लिए व्हाट्सप्प पर कांटेक्ट करे।
                </span>
              </button>
            </a>
          </div>
        </div>
      </div>
      {/* // <div className='rightContainer'>
            //     <Rightcontainer/>
            // </div> */}
    </>
  );
}
